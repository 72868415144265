<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '90vw'}" :style="{width: '90vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-file" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Detalles del envío</strong>
        </h4>
      </div>
    </template>
    <Card class="w-full">
      <template #content>
        <div class="w-full text-xxs">
          <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
            <div class="grid grid-rows-4 gap-1 pt-3">
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Código Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="modelEnvio.CardCode" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Nombre Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="modelEnvio.CardName" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Usuario Remitente: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="modelEnvio.user_entrega" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Usuario responsable: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="modelEnvio.user_recibe" disabled/>
                </div>
              </div>
            </div>
            <div class="grid grid-rows-4 gap-1 pt-1">
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Número Documento </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="modelEnvio.nro_orden" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de Creación: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="modelEnvio.fecha_crea" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Bodega Origen: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="modelEnvio.WhsCode" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Bodega Destino: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="modelEnvio.ToWareHouseCode"
                             disabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" class="mt-6">
          <div>
            <DataTable
              :value="modelEnvio.detalle"
              class="p-datatable-sm text-xxs"
              showGridlines
              dataKey="LineNum"
              responsiveLayout="scroll"
              :scrollable="true"
              scrollHeight="500px"
            >
              <template #empty>
                No hay datos para mostrar
              </template>
              <Column field="tipo_doc" header="Tipo" headerStyle="justify-content: center;text-align: center"
                      align="left" style="min-width:3rem; max-width: 3rem;"
                      bodyStyle="justify-content: center;text-align: center"/>
              <Column field="LineNum" header="Indice" headerStyle="justify-content: center;text-align: center"
                      style="min-width:3rem; max-width: 3rem;"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ data.LineNum }}</div>
                </template>
              </Column>
              <Column field="ItemCode" header="Artículo" headerStyle="justify-content: center;text-align: center"
                      align="left" style="min-width:32rem">
                <template #body="{data}">
                  <div class="grid grid-cols-1 w-full">
                    <div class="flex justify-between items-center my-1">
                      <div>
                        <strong>Código Mx: </strong>{{ data.item_code }}
                      </div>
                      <div class="flex gap-2">
                        <Badge v-if="data.Controlado" value="Controlado"
                               class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.Regulado" value="Regulado"
                               class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.cadenaFrio" value="Cadena Frio"
                               class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                      </div>
                    </div>
                    <div>
                      <strong>Nombre: </strong>{{ data.ItemName }}
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="" header="Lotes" headerStyle="justify-content: center;"
                      style="max-width:5rem" bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>
                    <Button type="button" class="p-button-xs" icon="pi pi-eye" label="Ver"
                            @click="toggleLotes($event,data)"
                            aria-haspopup="true" aria-controls="overlay_panel"/>
                    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 400px"
                                  :breakpoints="{'960px': '75vw'}">
                      <DataTable
                        :value="lotesList"
                        class="p-datatable-sm text-xs"
                        showGridlines
                        dataKey="id"
                        responsiveLayout="scroll"
                      >
                        <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;"
                                style="min-width:5rem"
                                bodyStyle="text-align: center"/>
                        <Column field="ExpDate" header="Fec. Vencimiento" headerStyle="justify-content: center;"
                                style="min-width:5rem"
                                bodyStyle="text-align: center"/>
                        <Column field="CantidadLote" header="Cantidad" headerStyle="justify-content: center;"
                                style="min-width:5rem"
                                bodyStyle="text-align: center">
                          <template #body="{data}">
                            <div>{{ $h.formatNumber(data.Quantity) }}</div>
                          </template>
                        </Column>
                      </DataTable>
                    </OverlayPanel>
                  </div>
                </template>
              </Column>
              <Column field="Quantity" header="Cantidad"
                      headerStyle="justify-content: center;text-align: center"
                      style="max-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatNumber(data.lotes.reduce((acc, val) => acc + val.Quantity, 0)) }}</div>
                </template>
              </Column>
              <Column field="Price" header="Precio Unitario" headerStyle="justify-content: center;text-align: center"
                      style="max-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatCurrency(data.UnitPrice) }}</div>
                </template>
              </Column>
              <Column field="LineTotal" header="Total linea" headerStyle="justify-content: center;text-align: center"
                      style="max-width:8rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{
                      $h.formatCurrency(data.lotes.reduce((acc, val) => acc + val.Quantity, 0) * data.UnitPrice)
                    }}
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </template>
    </Card>
    <Card class="w-full mt-4">
      <template #content>
        <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8">
          <div class="grid text-xxs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
            <div class="flex flex-col">
              <p class="mb-2">
                <strong>Comentarios: </strong>
              </p>
              <Textarea
                class="p-inputtext-xxs"
                disabled
                :autoResize="true"
                rows="2"
                v-model="modelEnvio.notas"
                cols="40"/>
            </div>
          </div>
          <div class="text-xxs lg:col-span-1">
            <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
              <span class="font-bold">Total:</span>
              <span class="">{{ $h.formatCurrency(total) }}</span>
            </div>
          </div>
        </div>
        <div class="flex justify-end align-center mt-2">
          <Button
            icon="pi pi-send"
            @click="infoEnvio(modelEnvio)"
            label="Información"
            class="p-button-rounded p-button-success"
          />
        </div>
      </template>
    </Card>
  </Dialog>
</template>

<script>
import { computed, ref } from 'vue'
import ControlEnvioService from '@/apps/pharmasan/compras/logistica/services/control.service'

export default {
  name: 'modalDetailsEnvio',
  setup () {
    const displayModal = ref(false)
    const _ControlEnvioService = new ControlEnvioService()
    const modelEnvio = ref({})
    const lotesList = ref([])
    const op = ref()
    const total = computed(() => {
      return modelEnvio.value.detalle.reduce((acc, value) => acc + (value.lotes.reduce((acc, val) => acc + val.Quantity, 0) * value.UnitPrice), 0)
    })
    const openModal = (values) => {
      modelEnvio.value = values
      displayModal.value = true
    }
    const toggleLotes = (event, data) => {
      lotesList.value = data.lotes
      op.value.toggle(event)
    }
    const infoEnvio = async ({ id }) => {
      await _ControlEnvioService.infoEnvio(id)
    }
    return {
      openModal,
      displayModal,
      modelEnvio,
      lotesList,
      toggleLotes,
      op,
      total,
      infoEnvio
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.footerModal {
  display: flex;
  /*justify-content: space-between;*/
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}

::v-deep(.lineaCerrada) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
</style>
